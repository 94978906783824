<template>
  <l-map
    ref="map"
    :zoom="zoom"
    :center="center"
    :options="mapOptions"
    @update:center="centerUpdate"
    @update:zoom="zoomUpdate"
  >
    <l-tile-layer :url="url" :attribution="attribution" />

    <l-marker :lat-lng.sync="marker" :draggable="draggable"></l-marker>

    <l-circle
      v-if="isPrimaryAddress"
      :lat-lng="marker"
      :radius="deliveryRadius"
      :opacity="0.2"
      color="#FAA225"
      fillColor="#FAA225"
    >
      <l-popup :content="getContent()" />
    </l-circle>
    <l-circle :lat-lng="marker" :radius="collectionRadius" :opacity="0.2" />
  </l-map>
</template>

<script>
  import Vue from "vue";
  import { LMap, LTileLayer, LMarker, LCircle, LPopup } from "vue2-leaflet";
  import L from "leaflet";

  export default Vue.extend({
    name: "LeafletMap",

    components: { LMap, LTileLayer, LMarker, LCircle, LPopup },

    props: {
      latLng: {
        type: Object,
        default: () => {},
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      deliveryRadius: {
        type: Number,
        default: 0,
      },
      collectionRadius: {
        type: Number,
        default: 0,
      },
      isPrimaryAddress: {
        type: Boolean,
        default: false,
      },
      draggable: {
        type: Boolean,
        default: false,
      },
    },

    watch: {
      latLng: {
        deep: true,
        immediate: true,
        handler(latLng, oldLatLng) {
          this.center = L.latLng(latLng.lat, latLng.lng);
          this.marker = L.latLng(latLng.lat, latLng.lng);
        },
      },
      marker: {
        deep: true,
        immediate: true,
        handler(newVal, oldVal) {
          if (newVal.lat != oldVal.lat && newVal.lng != oldVal.lng) {
            this.$emit("update:latLng", newVal);
          }
        },
      },
    },

    data: function () {
      return {
        zoom: 15,
        center: L.latLng(47.41322, -1.219482),
        url: "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        marker: L.latLng(47.41322, -1.219482),
        currentZoom: 11.5,
        currentCenter: L.latLng(47.41322, -1.219482),
        mapOptions: {
          zoomSnap: 0.5,
        },
      };
    },

    methods: {
      setDefaultIcon() {
        delete L.Icon.Default.prototype._getIconUrl;
        L.Icon.Default.mergeOptions({
          iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
          iconUrl: require("@/assets/ic_Map Marker.png"),
          shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
        });
      },
      onMapClick(clickEvent) {
        if (!this.readonly) this.$emit("update:latLng", clickEvent.latlng);
      },
      zoomUpdate(zoom) {
        this.currentZoom = zoom;
      },
      centerUpdate(center) {
        this.currentCenter = center;
      },
      getContent() {
        let distanceInKm = this.deliveryRadius / 1000;
        return `Delivery Radius: ${distanceInKm}km`;
      },
    },

    mounted: function () {
      this.setDefaultIcon();
      // @ts-ignore
      this.$refs.map.mapObject._onResize();
    },
  });
</script>
